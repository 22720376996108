body {
  min-height: 100vh;
  min-width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: #000000;
}
canvas {
  margin: 0;
  height: 100vmin;
  width: 100vmin;
  image-rendering: high-quality;
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);
  webkit-forced-dark-mode: on;
}

canvas.fullscreen {
  height: 100vh;
  width: 100vw;
}
